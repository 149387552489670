import {Component, Input, OnInit} from '@angular/core';
import {Event} from '../../models/event';
import {Router} from '@angular/router';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.css']
})
export class EventComponent implements OnInit {
  @Input() event: Event;
  public isCollapsed = true;
  constructor(private router: Router) { }

  ngOnInit(): void {
  }
  onClick(index) {
    // this.event = this.events[index];
    this.router.navigate(['/event', index]);
  }
}
