import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppComponent} from './app.component';
import {PageHeaderComponent} from './page-header/page-header.component';
import {RouterModule} from '@angular/router';
import {AppRoutingModule} from './app-routing.module';
import {PageHomeComponent} from './page-home/page-home.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatSelectModule} from '@angular/material/select';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AlphafotoApiInterceptor} from './alphafoto-api.interceptor';
import {SearchComponent} from './search/search.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { EventsComponent } from './events/events.component';
import { CarouselComponent } from './carousel/carousel.component';
import { EventPageComponent } from './event-page/event-page.component';
import { NewsComponent } from './news/news.component';
import { EventComponent } from './events/event/event.component';

@NgModule({
  declarations: [
    AppComponent,
    PageHeaderComponent,
    PageHomeComponent,
    SearchComponent,
    EventsComponent,
    CarouselComponent,
    NewsComponent,
    CarouselComponent,
    EventPageComponent,
    EventComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    RouterModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSelectModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule
  ],
  providers: [HttpClientModule,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AlphafotoApiInterceptor,
      multi: true
    }],
  bootstrap: [AppComponent]
})
export class AppModule {
}
