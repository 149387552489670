import {Component, Input, OnInit} from '@angular/core';
import {PreviewImages} from '../models/preview-images';
import {Event} from '../models/event';
import {ActivatedRoute} from '@angular/router';
import {EventsService} from '../services/events.service';
import {AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators} from '@angular/forms';

export function allowedNameValidator(nameRe: RegExp): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const allowed = nameRe.test(control.value);
    return !allowed ? {allowedName: {value: control.value}} : null;
  };
}

@Component({
  selector: 'app-event-page',
  templateUrl: './event-page.component.html',
  styleUrls: ['./event-page.component.css']
})
export class EventPageComponent implements OnInit {
  event: Event;
  previewImages: PreviewImages[];
  searchForm: FormGroup;
  newsTitle: string;
  readonly pattern: RegExp = new RegExp('^\\d+$');

  private validationMessages = {
    startNumber: {
      required: {
        en: 'Please input start number',
        de: 'Please input start number DE'
      },
      digit: {
        en: 'Please input correct start number',
        de: 'Please input correct start number DE'
      }
    }
  };

  constructor(private route: ActivatedRoute, private eventService: EventsService, private fb: FormBuilder) {
    this.createForm();
  }

  ngOnInit(): void {
    this.newsTitle = 'Informationen';
    this.eventService.getEventById(+this.route.snapshot.paramMap.get('id')).subscribe(data => {
      this.event = data;
      this.previewImages = this.event.previewImages;
    });
  }

  public getErrorMessage(field, validationCriteria): string {
    let result = this.validationMessages[field][validationCriteria].en;
    console.log(result)
    return result;
  }

  createForm(): void {
    this.searchForm = this.fb.group({
      startNumber: ['',
        Validators.compose(
          [Validators.required, allowedNameValidator(this.pattern)])]
    });
  }

  search(): void {
    const formData = this.searchForm.getRawValue();
    console.log(formData.startNumber);
  }
}
