import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Event} from '../models/event';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  private eventsUrl = '/events';

  constructor(private http: HttpClient) {
  }

  getPageImages(): Observable<Event[]> {
    return this.http.get<Event[]>(this.eventsUrl);
  }

  getEventById(id: number): Observable<Event> {
    return this.http.get<Event>(this.eventsUrl + '/' + id);
  }
}
