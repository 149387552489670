import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {

  searchForm: FormGroup;

  private validationMessages = {
    event: {
      en: 'Please input event name',
      de: 'Please input event name DE'
    },
    startNumber: {
      en: 'Please input correct start number',
      de: 'Please input correct start number DE'
    }
  };

  constructor(private fb: FormBuilder) {
    this.createForm();
  }

  ngOnInit(): void {
  }

  createForm(): void {
    this.searchForm = this.fb.group({
      event: ['', Validators.required],
      startNumber: ['', Validators.pattern('^\\d+$')]
    });
  }

  search(): void {
    this.checkErrors(false);
  }

  public getErrorMessage(field): string {
    return this.validationMessages[field].en;
  }

  private checkErrors(ignorePristine): void {
  }
}
