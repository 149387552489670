import { Component, OnInit } from '@angular/core';
import {EventsService} from '../services/events.service';
import {Event} from '../models/event';
import {Router} from '@angular/router';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css']
})
export class EventsComponent implements OnInit {
  events: Event[];
  constructor(private eventsService: EventsService) { }

  ngOnInit(): void {
    this.eventsService.getPageImages().subscribe(data => {
      this.events = data;
    });
  }


}
