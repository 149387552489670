import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../environments/environment';

@Injectable()
export class AlphafotoApiInterceptor implements HttpInterceptor {

  private REST_API_SERVER = environment.aplphafotoApiUrl;
  private REST_LOCAL_API_SERVER = environment.aplphafotoLocalApiUrl;

  constructor() {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    request = request.clone({
      url: this.getRequestUrl(request.url)
    });
    return next.handle(request);
  }

  public getRequestUrl(path: string): string {

    if (path.substr(0, 1) === '/') {
      path = path.substr(1);
    }

    if (typeof window !== 'undefined' && window.document && window.location.hostname !== 'localhost') {
      return this.REST_API_SERVER + path;
    }

    return this.REST_LOCAL_API_SERVER + path;
  }

}
