<div class="container">
  <p class="event-name">X {{event.name}}, {{event.date}}</p>
  <div class="event-logo"><img src="{{event.logo}}"></div>
  <div style="padding: 10px 0;">
    <app-carousel [imagesList]="previewImages"></app-carousel>
  </div>
  <app-news [newsTitle]="newsTitle" [newsMessage]="event.news"></app-news>
  <p class="meine-builder-finden">Meine Bilder finden</p>
  <div class="search">
    <div class="search-form">
      <div class="form-group" [formGroup]="searchForm" (keyup.enter)="search()">
        <label class="start-number-search">
          <img src="../../assets/search/search.svg" class="search-icon" alt="">
          <input type="search" formControlName="startNumber" class="form-control form-control-event-search"
                 [ngClass]="{'input-error': searchForm.controls['startNumber'].invalid && (searchForm.controls['startNumber'].dirty || searchForm.controls['startNumber'].touched)}"
                 placeholder="Eventname, Jahr / Datum">
        </label>
        <div class="form-error">
          <p
            *ngIf="searchForm.controls['startNumber'].invalid && (searchForm.controls['startNumber'].dirty || searchForm.controls['startNumber'].touched) && searchForm.controls['startNumber'].errors.required">
            {{ getErrorMessage('startNumber', 'required') }}
          </p>
          <p
            *ngIf="searchForm.controls['startNumber'].invalid && (searchForm.controls['startNumber'].dirty || searchForm.controls['startNumber'].touched) && searchForm.controls['startNumber'].errors.allowedName && !searchForm.controls['startNumber'].errors.required">
            {{ getErrorMessage('startNumber', 'digit') }}
          </p>
        </div>
      </div>
      <button class="search-button" (click)="search()">Suchen</button>
    </div>
  </div>
</div>
