<div id="carouselExampleIndicators" #carousel class="carousel slide" data-ride="carousel">
  <ol class="carousel-indicators" id="11">
    <li *ngFor="let image of imagesList; index as i" data-target="#carouselExampleIndicators"
        [ngClass]="(i==0)?'active':''" data-slide-to="{{i}}"></li>
  </ol>
  <div class="carousel-inner" (swiperight)="prev()" (swipeleft)="next()">
    <div *ngFor="let image of imagesList; index as i" class="carousel-item"
         [ngClass]="(i==0)?'active':''">
      <div class="d-block start-page-image background-image"
           [ngStyle]="{ 'background-image': 'url(' + image.src + ')'}">
      </div>
    </div>
  </div>
  <div *ngIf="imagesList != null">
    <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>
</div>
