<div class="search">
  <div class="search-form">
    <div class="form-group" [formGroup]="searchForm" (keyup.enter)="search()">
      <label class="event-search">
        <img src="../../assets/search/search.svg" class="search-icon" alt="">
        <input type="search" formControlName="event" class="form-control form-control-event-search"
               [ngClass]="{'input-error': searchForm.controls['event'].invalid && (searchForm.controls['event'].dirty || searchForm.controls['event'].touched)}"
               placeholder="Eventname, Jahr / Datum">
      </label>
      <div class="form-error form-error-mob">
        <p
          *ngIf="searchForm.controls['event'].invalid && (searchForm.controls['event'].dirty || searchForm.controls['event'].touched)">
          {{ getErrorMessage('event') }}
        </p>
      </div>
      <label class="start-number-search">
        <input type="search" formControlName="startNumber" class="form-control"
               [ngClass]="{'input-error': searchForm.controls['startNumber'].invalid && (searchForm.controls['startNumber'].dirty || searchForm.controls['startNumber'].touched)}"
               placeholder="Startnummer">
      </label>
      <div class="form-error form-error-mob">
        <p
          *ngIf="searchForm.controls['startNumber'].invalid && (searchForm.controls['startNumber'].dirty || searchForm.controls['startNumber'].touched)">
          {{ getErrorMessage('startNumber') }}
        </p>
      </div>
    </div>
    <button class="search-button" (click)="search()">Suchen</button>
  </div>
  <div class="form-error-desk">
    <div class="form-error">
      <p
        *ngIf="searchForm.controls['event'].invalid && (searchForm.controls['event'].dirty || searchForm.controls['event'].touched)">
        {{ getErrorMessage('event') }}
      </p>
    </div>
    <div class="form-error">
      <p
        *ngIf="searchForm.controls['startNumber'].invalid && (searchForm.controls['startNumber'].dirty || searchForm.controls['startNumber'].touched)">
        {{ getErrorMessage('startNumber') }}
      </p>
    </div>
  </div>
</div>

