import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PageHomeComponent} from './page-home/page-home.component';
import {RouterModule} from '@angular/router';
import {EventPageComponent} from './event-page/event-page.component';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot([
      {path: '', component: PageHomeComponent, data: {title: 'Alphafoto'}},
      {path: 'event', children:   [
          {path: ':id', component: EventPageComponent}
        ]
      }
    ])],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
