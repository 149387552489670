import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {PreviewImages} from '../models/preview-images';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css']
})
export class CarouselComponent implements OnInit {
  @Input() imagesList: PreviewImages[];
  @ViewChild('carousel', {static: false}) carousel: any;
  constructor() { }

  ngOnInit(): void {
  }
  next(): void {
    this.carousel.next();
  }

  prev(): void {
    this.carousel.prev();
  }
}
