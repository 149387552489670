import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.css']
})
export class PageHeaderComponent implements OnInit {
  iconUrl = '../../assets/page-header/menu.svg';
  selectedCountry = 'DE';
  countries: any[] = [
    { name: 'German', code: 'DE' },
    { name: 'France', code: 'FR' },
    { name: 'Italy', code: 'IT' },
  ];
  constructor() {
  }

  ngOnInit(): void {
  }

  toggleIcon(): void {
    if (this.iconUrl === '../../assets/page-header/menu.svg') {
      this.iconUrl = '../../assets/page-header/cross.svg';
    } else {
      this.iconUrl = '../../assets/page-header/menu.svg';
    }
  }
}
