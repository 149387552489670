import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {StartPageInfo} from '../models/start-page-info';

@Injectable({
  providedIn: 'root'
})
export class StartPageInfoService {

  private startPageInfoUrl = 'startPageNews';

  constructor(private http: HttpClient) {
  }

  getNews(): Observable<StartPageInfo> {
    return this.http.get<StartPageInfo>(this.startPageInfoUrl);
  }

}
