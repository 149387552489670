import {Component, OnInit, ViewChild} from '@angular/core';
import {StartPageImagesService} from '../services/start-page-images.service';
import {PreviewImages} from '../models/preview-images';
import {StartPageInfoService} from '../services/start-page-info.service';

@Component({
  selector: 'app-page-home',
  templateUrl: './page-home.component.html',
  styleUrls: ['./page-home.component.css'],
})
export class PageHomeComponent implements OnInit {

  previewImages: PreviewImages[];
  newsTitle: string;
  newsMessage: string;

  constructor(private startPageImagesService: StartPageImagesService, private startPageInfoService: StartPageInfoService) {
  }


  ngOnInit(): void {
    this.newsTitle = 'Informationen';
    this.newsMessage = 'Gravel Ride&Race in Bern: Bilder selber suchen, da kaum Startnummern sichtbar.' +
      '<br>' +
      '<br>' +
      'Die Startnummern waren praktisch nie sichtbar, weil auf dem Rücken. ' +
      'SUCHE DEINE BILDER in der Rubrik BILDER OHNE ERKENNBARE NUMMER, nebenan. Es hat viele schöne Actionbilder - es lohnt sich:)';
    this.getStartPageImages();
    this.getStartPageInfo();
  }

  getStartPageImages(): void {
    this.startPageImagesService.getPageImages().subscribe(data => {
        this.previewImages = data;
      },
      error => {
        console.log(error);
      });
  }

  getStartPageInfo(): void {
    /*REMOVE AFTER API IS WORKING*/
    this.startPageInfoService.getNews().subscribe(data => {
        this.newsMessage = data.news;
      },
      error => {
        console.log(error);
      });
  }

}
