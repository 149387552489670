<div class="container">
  <header class="blog-header py-3">
    <div class="row flex-nowrap justify-content-between align-items-center">
      <div class="col-4 pt-1">
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExample01"
                aria-controls="navbarsExample01" aria-expanded="false" aria-label="Toggle navigation">
          <div class="text-muted" ><img (click)="toggleIcon()" src="{{iconUrl}}"></div>
        </button>
      </div>
      <div class="col-4 text-center">
        <a class="blog-header-logo text-dark" href="#"><img src="../../assets/page-header/logo.svg"></a>
      </div>
      <div class="col-4 d-flex justify-content-end align-items-center">
        <a class="text-muted" href="#"><img src="../../assets/page-header/cart.svg"></a>
      </div>
    </div>
    <div class="row">
      <div class="toolbar col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <div class="collapse navbar-collapse" id="navbarsExample01">
          <ul class="navbar-nav mr-auto">
            <li class="nav-item border-0 padding-top">
              <mark>SPRACHE</mark>
              <mat-select class="selector" [placeholder]="selectedCountry">
                <mat-option *ngFor="let country of countries" value="{{country.name}}">{{country.code}}</mat-option>
              </mat-select>
            </li>
            <li class="nav-item">
              <a class="nav-link">
                <div class="row">
                  <div class="col">
                    <p>Wähle Das Thema <mark>(Dunkel / Licht)</mark></p>
                  </div>
                  <div class="col text-right">
                    <div class="custom-control custom-switch">
                      <input type="checkbox" class="custom-control-input" id="customSwitch1">
                      <label class="custom-control-label" for="customSwitch1"></label>
                    </div>
                  </div>
                </div>
              </a>
            </li>
            <li class="nav-item padding-top">
              <mark>SPEISEKARTE</mark>
              <a class="nav-link" href="#">Meine Bilder</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">Hilfe / FAQ's</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">Über uns</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">Zahlungsarten</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">Info für Veranstalter</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">AGBs</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">Kontakt</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </header>
</div>
