<div class="event-item">
  <div class="images" style="position: relative">
    <div class="info" (click)="collapse.toggle();" role="button">
      <p style="display: inline-block">Info</p>
      <img src="assets/search/close.svg" style="right: 10px; top: 5px; position: absolute;" [ngStyle]="{display: isCollapsed ? 'none': 'block'}">
    </div>
    <div class="news-message" #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
      <div style="
    padding: 5px 0;
    height: 90%;
    overflow: hidden;
    overflow-y: scroll;
">
        <p style="font-size: 14px;">{{event.news}}</p>
      </div>
    </div>
    <img class="preview" src="{{event.mainPreviewImage}}" (click)="onClick(event.id)">
    <div class="logo"><img src="{{event.logo}}" (click)="onClick(event.id)"></div>
  </div>
  <p class="event-name" (click)="onClick(event.id)">{{event.name}}</p>
</div>
<!--(click)="onClick(event.id)"-->
