import {Injectable} from '@angular/core';
import {PreviewImages} from '../models/preview-images';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StartPageImagesService {

  private startPageImagesUrl = 'start/images';

  constructor(private http: HttpClient) {
  }

  getPageImages(): Observable<PreviewImages[]> {
    return this.http.get<PreviewImages[]>(this.startPageImagesUrl);
  }

}
