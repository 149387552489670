<div class="news">
  <div class="news-button"
       (click)="collapse.toggle(); rotate()" role="button" [attr.aria-expanded]="!isCollapsed"
       aria-controls="collapsInfo">
    <p class="news-text">{{newsTitle}}</p>
    <div class="news-arrow" [@arrowRotatedState]='arrowState'></div>
  </div>
  <div class="news-message" #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
    <div style="height: 20px"></div>
    <div [innerHTML]="newsMessage"></div>
    <div style="height: 20px"></div>
  </div>
</div>
