import {Component, Input, OnInit} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {StartPageInfoService} from '../services/start-page-info.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css'],
  animations: [
    trigger('arrowRotatedState', [
      state('default', style({transform: 'rotate(0)'})),
      state('rotated', style({transform: 'rotate(-180deg)'})),
      transition('rotated => default', animate('300ms')),
      transition('default => rotated', animate('300ms'))
    ])
  ]
})
export class NewsComponent implements OnInit {

  arrowState = 'default';
  public isCollapsed = true;
  @Input()
  newsMessage: string;
  @Input()
  newsTitle: string;

  constructor() {
  }

  ngOnInit(): void {
  }

  rotate(): void {
    this.arrowState = (this.arrowState === 'default' ? 'rotated' : 'default');
  }

}
